<template>
  <ul class="menu-line menu-ul pl-2 pb-1">
    <li v-for="(link, index) in links"
        :key="index"
        class="menu-li"
    >
      <a :class="{active: selectedLink === index}"
         class="menu-selected-line c-pointer"
         @click="selectLink(index)"
      >{{ link.value }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SimpleMenu',
  props: {
    // list에 key, value 형식 -> [{key: '', value: ''}]
    links: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedLink : 0,
    }
  },
  created() {

  },
  methods: {
    selectLink(index) {
      this.selectedLink = index;
      this.$emit('link-clicked', this.links[index]); //선택값 부모 컴포넌트에 return
    }
  }

}
</script>

<style>
.menu-line {
  display: -webkit-box;
  display: flex;
  list-style: none;
  border-bottom: solid 1px #e8e8e8;
}

ul.menu-ul {
  list-style: none;
  padding: 0;
}

li.menu-li {
  display: inline-block;
  margin-right: 30px;
}

a.menu-selected-line {
  text-decoration: none;
  color: #8a8a8a;
  padding-bottom: 5px;
}

a.menu-selected-line.hover {
  color: #5e5e5e;
}

a.menu-selected-line.active {
  transition: 0.1s ease-in-out;
  color: #000000;
  border-bottom: solid 2px #000000;
  font-weight: bold;
}
</style>