<template>
  <div id="wrapper" class="container" style="max-width: 1000px;">
    <div class="pt-3 mt-3 card">

      <div class="px-3 py-2">
        <div class="top-title pb-1">
          <h3><BIconArchive class="mx-2"/>쿠폰</h3>
<!--          <div class="mb-4" style="display: flex; border-bottom: solid 1px #afafaf; justify-content: space-between; align-items: flex-end;" />-->
          <div class="mt-5">
            <SimpleMenu :links="links" @link-clicked="linkClickHandle"/>
          </div>
        </div>

        <div v-if="selectedLink.key === links[0].key" class="middle-content">
          <div v-if="usedCouponList.length === 0">
            <span>쿠폰 사용내역이 없습니다</span>
          </div>

          <div class="ticket" v-else v-for="(usedCoupon, index) in usedCouponList" :key="index">
            <div style="border-right: 2px dashed #d3d3d3;">
              <img class="ml-3 mr-3" src="@/assets/svg/icon-x-tools.svg" alt="xcost-tools-icon" width="70px" height="70px">
            </div>
            <div class="ml-3 text-left">
              <div class="display-base">
                <p class="title-txt">{{ usedCoupon.name }} ({{ usedCoupon.applicable_days }}일)</p>
                <Tooltip :text="usedCoupon.disp_names">
                  <BIconThreeDots />
                </Tooltip>
              </div>
              <p class="sub-txt">사용일: {{ momentDate(usedCoupon.used_date) }}</p>
            </div>
          </div>

          <div class="mt-5" v-if="rows > perPage">
            <b-pagination
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
                hide-ellipsis
                align="fill"
                prev-text="<"
                next-text=">"
                first-text="|◀"
                last-text="▶|"
            >
            </b-pagination>
          </div>
        </div>
        <div v-if="selectedLink.key === links[1].key" class="middle-content">
          <div class="mw-600p">
            <h3 class="coupon-title">쿠폰등록</h3>
            <b-form-input v-model="couponCode" size="lg" id="input-coupon" placeholder="쿠폰번호를 입력해주세요"></b-form-input>
            <b-button class="mt-2" block variant="howcost" @click="registerCoupon">등록하기</b-button>
          </div>
          <div class="pt-3 text-left mw-640p fc-g">
            <ul>
              <li>유효기간이 지난 쿠폰은 등록이 불가합니다.</li>
              <li>쿠폰에 적힌 하이픈(-)까지 모두 입력해주세요.</li>
              <li>지급될 제품이 모두 보유중이라면 쿠폰이 사용되지 않습니다.</li>
              <li>지급 제품 일부가 보유중 일 경우 미보유한 제품만 적용됩니다.</li>
              <li>쿠폰은 소/대문자를 구분하며 실물과 정확히 일치하게 입력해주세요.</li>
              <li>이미 사용된 쿠폰은 적용되지 않으며 제품에 대해 중복 적용 및 지급이 되지 않습니다.</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import SimpleMenu from "@/xcost/home/components/SimpleMenu.vue";
  import Tooltip from "@/xcost/home/components/Tooltip.vue";

  import {
    alertWarn,
    apiCall,
    commify,
    warpSite
  } from '../../../common/utils';
  import moment from "moment";

  // 사용내역 보관함 쿠폰등록

  export default {
    name: 'CouponUse',
    components: { SimpleMenu, Tooltip },
    props: ['memberToken'],
    // comments: { SimpleMenu },
    data () {
      return {
        links: [ // 메뉴
          { key: 'couponHistory', value: '사용내역' },
          // { key: 'coupon-storage', value: '보관함' },
          { key: 'couponRegister', value: '쿠폰등록' },
        ],
        couponCode: '',
        selectedLink: {},
        currentPage: 1,
        perPage: 10,
        rows: 0,
        usedCouponList: [],
      }
    },
    watch: {
      currentPage: "getUsedCoupon",
      selectedLink: "reloadCoupons",
    },
    created(){
      this.selectedLink = this.links[0]; // default 선택값 (첫번째 메뉴)
      // this.getUsedCoupon();
    },
    beforeMount() {

    },
    mounted(){

    },
    computed: {

    },
    methods: {
      momentDate(dt) { return moment(dt).format('YYYY-MM-DD HH:mm'); },

      linkClickHandle(link) {
        this.selectedLink = link;
        // console.log("linkClickHandle: ", this.selectedLink)
      },

      async getUsedCoupon() {
        try {
          const qs = `currentPage=${this.currentPage}&perPage=${this.perPage}`;
          const r = await apiCall('GET', `/api/coupon/license/list?${qs}`);
          this.usedCouponList = r.result.pageResult;
          this.rows = r.result.rows;
          // console.log(r);
        } catch (e) {
          console.log(e);
        }
      },

      async registerCoupon() {
        try {
          const r = await apiCall('POST', `/api/coupon/license/register`, { couponCode: this.couponCode });
          if (r.code === 200) {
            this.couponCode = '';
            alert(r.message);
            location.reload();
          } else {
            await alertWarn( this.$bvModal, r.message, "쿠폰등록 실패");
          }
        } catch (e) {
          console.log("catch =======>", e);
          await alertWarn( this.$bvModal, "예기치 못한 오류 입니다. 증상이 지속될 시 관리자에게 문의하세요.", "쿠폰등록 실패");
        }
      },

      async reloadCoupons() {
        if (this.selectedLink.key === this.links[0].key) {
          await this.getUsedCoupon();
        }
      },
    }

  }
</script>

<style>
.mw-600p {
  max-width: 600px;
}

.mw-640p {
  max-width: 640px;
}

p.title-txt {
  font-size: 14pt;
  font-weight: bold;
}
h3.coupon-title {
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 20px;
}
p.sub-txt {
  font-size: 10pt;
  color: #959595;
}

div.middle-content {
  text-align: -webkit-center;
  margin: 1rem 0;
}

div.ticket {
  position: relative;
  max-width: 900px;
  height: auto;
  background-color: #f8f8f8;
  border-radius: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  box-shadow: 0 2px 4px #cfcfcf;
  padding: 1rem 0;
  margin-bottom: 1rem;
}
</style>